import { graphql } from 'gatsby'
import * as React from 'react'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import Gallery from '@browniebroke/gatsby-image-gallery'

const PhotosPage = ({ data }) => {
    const images = data.images.edges.map(({ node }) => node.childImageSharp)

    return (
        <Layout>
        <Seo title="Photos" />
        <div className="max-w-7xl mx-auto">
            <div className="text-center">
              <p className="mt-10 mb-10 text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">
                Photos
              </p>
            </div>
            <Gallery
                images={images}
                gutter="0.5rem"
                rowMargin="0"
            />
        </div>
        </Layout>
    )
}

export const pageQuery = graphql`
  query ImagesForGallery {
    images: allFile(
        filter: { relativeDirectory: { eq: "media/photos" } }
        sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 300
              height: 300
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

export default PhotosPage
